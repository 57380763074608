import "core-js/stable";
import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import MetaInfo from "vue-meta-info";
import "@/components/ElementUi";
import "@/styles/variable.less";
import "@/styles/global.css";
Vue.use(MetaInfo);
Vue.config.productionTip = false;

const vm = new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
router.afterEach(() => {
  vm.$el.scrollTop = 0;
  // 兼容IE
  vm.$el.scrollTo(0, 0);
});
