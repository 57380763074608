<template>
  <footer id="foot">
    <div class="content">
      <div class="left">
        <img src="@/assets/images/logo-meta-02.png" alt="bookingmeta" />
        <ul v-if="!isIE11()">
          <li @click="$router.push('/about-us')">About Us</li>
          <li @click="$router.push('/privacy-policy')">Privacy Policy</li>
          <li @click="$router.push('/terms-agreement')">Terms & Agreement</li>
          <li @click="goToContact">Contact Us</li>
        </ul>
        <ul v-else>
          <li>
            <a href="/about-us" target="_top">About Us</a>
          </li>
          <li>
            <a href="/privacy-policy" target="_top">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms-agreement" target="_top">Terms & Agreement</a>
          </li>
          <li @click="goToContact">Contact Us</li>
        </ul>
      </div>
      <div class="right">
        <a href="https://www.facebook.com/bookingmetatech/"
          ><img src="@/assets/images/icon-facebook.png" alt="Facebook"
        /></a>
        <!-- <img src="@/assets/images/icon-twitter.png" alt="Twitter" /> -->
        <a href="https://api.whatsapp.com/send?phone=+85253161031&text=Hello"
          ><img src="@/assets/images/icon-whatsapp.png" alt="whatsapp"
        /></a>
        <a href="https://www.instagram.com/bookingmetatech/"
          ><img src="@/assets/images/icon-ins.png" alt="instagram"
        /></a>
        <!-- <img src="@/assets/images/icon-wechat.png" alt="WeChat" /> -->
      </div>
    </div>
  </footer>
</template>

<script>
import { ie11Mixins } from "@/utils/tools.js";
export default {
  mixins: [ie11Mixins],
  mounted() {
    this.anchorToFormByInterval();
  },
  methods: {
    goToContact() {
      if (!this.isIE11()) {
        this.$router.push({
          path: "/about-us",
          query: { form: true },
        });
        this.$nextTick(() => {
          this.anchorToFormByInterval();
        });
      } else {
        window.location.href = "/about-us?form=true";
      }
    },
    anchorToForm(timer) {
      let form = document.getElementById("contact-us");
      if (this.$route.query.form && form) {
        if (timer) {
          clearInterval(timer);
        }
        form.scrollIntoView(true);
      }
    },
    anchorToFormByInterval() {
      const timer = setInterval(() => {
        this.anchorToForm(timer);
      }, 50);
      setTimeout(() => {
        clearInterval(timer);
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
#foot {
  .content {
    @max-width();
    padding-top: 40px;
    padding-bottom: 120px;
    border-top: 1px solid #d2d2d2;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      img {
        width: 120px;
        height: 120px;
      }

      ul {
        line-height: 34px;
        font-weight: 600;

        li {
          cursor: pointer;

          &:hover {
            color: #0b4fd9 !important;
          }
        }
      }
    }

    .right {
      img {
        width: 30px;
        height: 30px;
        margin-top: 20px;
        margin-left: 10px;
      }
    }
  }
}
</style>
