class CookieManager {
    static SetCookie(c_name, value, expiredays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + expiredays);
        CookieManager.DelCookie();
        document.cookie = c_name + "=" + escape(value) +
            ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString() + "; path=/");
    }

    static GetCookie(c_name) {
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(c_name + "=");
            if (c_start != -1) {
                c_start = c_start + c_name.length + 1;
                var c_end = document.cookie.indexOf(";", c_start);
                if (c_end == -1) c_end = document.cookie.length;
                if (unescape(document.cookie.substring(c_start, c_end)) === "undefined") {
                    return "";
                }
                return unescape(document.cookie.substring(c_start, c_end));
            }
        }
        return "";
    }

    static DelCookie(name) {
        var exp = new Date();
        exp.setTime(exp.getTime() - 1);
        var cval = CookieManager.GetCookie(name);
        if (cval != null)
            document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
    }
}

const ie11Mixins = {
    methods: {
        isIE11: function () { return !!window.MSInputMethodContext && !!document.documentMode; }
    }
}

export default CookieManager;
export { ie11Mixins };