<template>
  <div id="app">
    <!-- <Header /> -->
    <div class="router-view"><router-view /></div>
    <Footer />
    <BackTop />
  </div>
</template>

<script>
// import Header from "@/components/Header";
import Footer from "@/components/Footer";
import BackTop from "@/components/BackToTop";
export default {
  name: "App",
  components: {
    // Header,
    Footer,
    BackTop,
  },
};
</script>

<style lang="less" scoped>
@import url("./styles/app.less");
</style>
