import Vue from "vue";
import {
  Button,
  Carousel,
  CarouselItem,
  Backtop,
  Form,
  FormItem,
  Select,
  Input,
  Option,
  OptionGroup,
  Dialog,
  Notification,
} from "element-ui";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
Vue.component(Button.name, Button);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Backtop.name, Backtop);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Select.name, Select);
Vue.component(Input.name, Input);
Vue.component(Option.name, Option);
Vue.component(Dialog.name, Dialog);
Vue.component(OptionGroup.name, OptionGroup);
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.prototype.$notify = Notification;
