<template>
  <el-collapse-transition>
    <div id="fix" v-if="headStatic">
      <div :class="{ active: demoFlag, 'demo-div': true }">
        <img src="@/assets/images/demo.png" alt="demo" @mouseenter="demoFlag = true" v-if="headStatic" />
        <div class="btn" v-show="demoFlag">
          <button @click="goToForm"><i class="el-icon-d-arrow-right"></i>Book a demo</button>
          <button class="mail-btn" @click="mailTo">
            <i class="el-icon-message"></i>sales@bookingmeta.com
          </button>
        </div>
      </div>
      <!-- <el-collapse-transition> -->
      <img src="@/assets/images/round-square.png" class="round-square" alt="demo-" v-show="demoFlag"
        @mouseenter="handleEnter" />
      <!-- </el-collapse-transition> -->
      <el-backtop target="#app" :bottom="60" :right="20" style="width: 80px">
        <div style="width: 100px; padding-top: 60px"></div>
        <div class="back-top">
          <img src="@/assets/images/back-top.png" alt="backtop" />
        </div>
      </el-backtop>
    </div>
  </el-collapse-transition>
</template>

<script>
import { EventBus } from "../../utils/eventBus";
export default {
  data() {
    return {
      demoFlag: false,
      headStatic: false,
      enterNum: 1,
    };
  },
  updated() {
    if (!this.headStatic) {
      this.demoFlag = false;
    }
  },
  mounted() {
    EventBus.$on("acceptProp", (msg) => (this.headStatic = msg));
  },
  methods: {
    mailTo: () => {
      let a = window.document.createElement('a');
      a.href = 'mailto:sales@bookingmeta.com';
      window.document.body.appendChild(a);
      a.click();
      a.remove();
    },
    goToForm() {
      this.$router.push({
        path: "/pricing",
        query: { form: true },
      });
      this.$nextTick(() => {
        this.anchorToForm();
      });
    },
    anchorToForm() {
      let form = document.getElementById("pricing-separate");
      if (this.$route.query.form && form) {
        form.scrollIntoView(true);
      }
    },
    handleEnter() {
      this.enterNum++;
      if (this.enterNum === 3) {
        this.demoFlag = false;
        this.enterNum = 1;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../styles/app.less");

.active {
  width: 360px;
  height: 170px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.demo-div {
  height: 70px;
}
</style>
