import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: [
    {
      path: "",
      name: "home",
      component: () => import("@/views/Home/index.vue"),
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("@/views/Home/index.vue"),
    },
    {
      path: "/solution",
      name: "Solution",
      component: () => import("@/views/Solution"),
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: () => import("@/views/Pricing"),
    },
    {
      path: "/q&a",
      name: "qa",
      component: () => import("@/views/Qa"),
    },
    {
      path: "/about-us",
      name: "AboutUs",
      component: () => import("@/views/AboutUs"),
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: () => import("@/views/PrivacyPolicy"),
    },
    {
      path: "/terms-agreement",
      name: "TermsAgreement",
      component: () => import("@/views/Agreement"),
    },
  ],
});
export default router;
